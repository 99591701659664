'use client';
import React, { useState, ReactNode } from 'react';
import styles from './styles/ToggleContent.module.css';

const ToggleContent = ({ toggle, content, showToggle }: any) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <div className="visible">{content.summary}</div>
            {showToggle ? (
                <>
                    {isExpanded && <div className="visible">{content.full}</div>}
                    <button className={styles.ToggleButton} onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? toggle.less : toggle.more}
                    </button>
                </>
            ) : (
                <div className="visible">{content.full}</div>
            )}
        </>
    );
};

export default ToggleContent;
