'use client';
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './skeleton.module.css';
import Image from 'next/image';

export interface ImageSkeletonProps {
    url: string;
    alternativeText: string | null;
    width: number;
    height: number;
    className?: string;
}

const assetsBaseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;

export const ImageSkeleton = ({ url, alternativeText, width, height, className }: ImageSkeletonProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className={`relative ${className}`}>
            {!isLoaded && ( // prettier-ignore
                <SkeletonTheme baseColor="#d7d9e0" highlightColor="#f1f1f1">
                    <Skeleton
                        // prettier-ignore
                        className={styles.skespan}
                        containerClassName={styles.skecontainer}
                    />
                </SkeletonTheme>
            )}
            <Image
                // prettier-ignore
                src={`${assetsBaseUrl}${url}`}
                alt={alternativeText ?? ''}
                width={width}
                height={height}
                onLoad={() => setIsLoaded(true)}
                priority
            />
        </div>
    );
};
