'use client';
// import Image from 'next/image';
import { BlocksRenderer, type BlocksContent } from '@strapi/blocks-react-renderer';
import { ImageSkeleton } from '@/app/components/Glob/Skeleton';

// type ImageType = {
//     name: string;
//     alternativeText?: string | null | undefined;
//     width: number;
//     height: number;
//     hash: string;
//     ext: string;
// };

const buildImagePath = (image: any) => {
    return `/uploads/${image.hash}${image.ext}`;
};

const renderImage = ({ image }: { image: any }) => {
    const imagePath = buildImagePath(image);
    return (
        <ImageSkeleton
            // prettier-ignore
            url={imagePath}
            alternativeText={image.alternativeText || ''}
            width={image.width}
            height={image.height}
        />
    );
};

export default function BlockRendererClient({ content }: { readonly content: BlocksContent }) {
    if (!content) return null;

    return (
        // prettier-ignore
        <BlocksRenderer
            content={content}
            blocks={{
                image: renderImage,
            }}
        />
    );
}
